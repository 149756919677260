exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tools-tsx": () => import("./../../../src/pages/admin/tools.tsx" /* webpackChunkName: "component---src-pages-admin-tools-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-company-research-index-tsx": () => import("./../../../src/pages/company_research/index.tsx" /* webpackChunkName: "component---src-pages-company-research-index-tsx" */),
  "component---src-pages-company-research-report-index-tsx": () => import("./../../../src/pages/company_research/report/index.tsx" /* webpackChunkName: "component---src-pages-company-research-report-index-tsx" */),
  "component---src-pages-company-research-report-privates-index-tsx": () => import("./../../../src/pages/company_research/report/privates/index.tsx" /* webpackChunkName: "component---src-pages-company-research-report-privates-index-tsx" */),
  "component---src-pages-datasets-[id]-tsx": () => import("./../../../src/pages/datasets/[id].tsx" /* webpackChunkName: "component---src-pages-datasets-[id]-tsx" */),
  "component---src-pages-datasets-index-tsx": () => import("./../../../src/pages/datasets/index.tsx" /* webpackChunkName: "component---src-pages-datasets-index-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-[id]-tsx": () => import("./../../../src/pages/insights/[id].tsx" /* webpackChunkName: "component---src-pages-insights-[id]-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-okta-tsx": () => import("./../../../src/pages/login/okta.tsx" /* webpackChunkName: "component---src-pages-login-okta-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-resources-[id]-tsx": () => import("./../../../src/pages/resources/[id].tsx" /* webpackChunkName: "component---src-pages-resources-[id]-tsx" */),
  "component---src-pages-resources-index-tsx": () => import("./../../../src/pages/resources/index.tsx" /* webpackChunkName: "component---src-pages-resources-index-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms_of_use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-tools-[id]-tsx": () => import("./../../../src/pages/tools/[id].tsx" /* webpackChunkName: "component---src-pages-tools-[id]-tsx" */),
  "component---src-pages-tools-index-tsx": () => import("./../../../src/pages/tools/index.tsx" /* webpackChunkName: "component---src-pages-tools-index-tsx" */)
}

